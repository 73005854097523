import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import lodash from "lodash";

function createOrder(order, products) {
  const customer = {
    name: order.name,
    lastname: order.lastname,
    phone: order.phone,
    email: order.email,
  };
  const address = {
    region: order.region,
    county: order.county,
    street: order.street,
    number: order.number,
  };

  const shipping = order.shipping;

  const totalInProducts = lodash.sum(
    order.products.map((product) => Number(product.price))
  );

  const totals = {
    total: totalInProducts + Number(order.shipping.serviceValue),
    subtotal: totalInProducts,
    shipping: Number(order.shipping.serviceValue),
  };
  return { customer, address, products: order.products, shipping, totals };
}

export const pay = createAsyncThunk("order/pay", async ({ order }) => {
  const newOrder = createOrder(order);
  const { data } = await axios.post(
    `${process.env.REACT_APP_DEVELOPMENT}makePayment`,
    { order: newOrder }
  );
  return data;
});

const initialState = {
  products: [],
  paymentRoute: "",
  paymentLoading: false,
};

export const cartReducer = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addProductToCart: (state, action) => {
      state.products = [...state.products, action.payload];
    },
    removeProductFromCart: (state, action) => {
      state.products = state.products.filter(
        (product) => product.id !== action.payload
      );
    },
  },
  extraReducers: {
    [pay.fulfilled]: (state, action) => {
      state.paymentRoute = action.payload;
    },
    [pay.pending]: (state, action) => {
      state.paymentLoading = true;
    },
  },
});

export const { addProductToCart, removeProductFromCart } = cartReducer.actions;

export default cartReducer.reducer;
