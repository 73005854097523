/* This example requires Tailwind CSS v2.0+ */
import { CalendarIcon, RefreshIcon, TruckIcon } from "@heroicons/react/outline";
import { Icon } from "../../components";

const perks = [
  {
    name: "10-year all-inclusive warranty",
    description: "We’ll replace it with a new one",
    icon: CalendarIcon,
  },
  {
    name: "Free shipping on returns",
    description: "Send it back for free",
    icon: RefreshIcon,
  },
  {
    name: "Free, contactless delivery",
    description: "The shipping is on us",
    icon: TruckIcon,
  },
];

export function Incentives() {
  return (
    <div className="bg-white">
      <h2 className="sr-only">Our perks</h2>
      <div className="max-w-7xl mx-auto divide-y divide-gray-200 lg:py-8 lg:flex lg:justify-center lg:divide-y-0 lg:divide-x">
        <div className="py-8 lg:py-0 lg:w-1/3 lg:flex-none">
          <div className="max-w-xs mx-auto px-4 flex items-center lg:max-w-none lg:px-8">
            <Icon icon="credit-card" className="h-8 w-8" />
            <div className="ml-4 flex-auto flex flex-col-reverse">
              <h3 className="font-medium text-gray-900">
                Compra con débito, crédito o webpay
              </h3>
              <p className="text-sm text-gray-500">Todas las formas de pago</p>
            </div>
          </div>
        </div>
        <div className="py-8 lg:py-0 lg:w-1/3 lg:flex-none">
          <div className="max-w-xs mx-auto px-4 flex items-center lg:max-w-none lg:px-8">
            <Icon icon="person-carry-box" className="h-8 w-8" />

            <div className="ml-4 flex-auto flex flex-col-reverse">
              <h3 className="font-medium text-gray-900">
                En toda la región metropolitana
              </h3>
              <p className="text-sm text-gray-500">Entregas 24 horas</p>
            </div>
          </div>
        </div>
        <div className="py-8 lg:py-0 lg:w-1/3 lg:flex-none">
          <div className="max-w-xs mx-auto px-4 flex items-center lg:max-w-none lg:px-8">
            <Icon icon="truck" className="h-8 w-8" />
            <div className="ml-4 flex-auto flex flex-col-reverse">
              <h3 className="font-medium text-gray-900">
                Mediante chilexpress y starken
              </h3>
              <p className="text-sm text-gray-500">Entregas en todo chile</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
