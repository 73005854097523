import { useState } from "react";
import {
  RegionInput,
  CountyInput,
  StreetInput,
  NumberInput,
} from "./chilexpress-inputs";
import { DeliveryMethods } from "./delivery-methods";
import { ConfirmAddress } from "../../components";
import { AddressDisplay } from "./address-display";

export const Form = ({ formik }) => {
  const [validatingAdress, setValidatingAdress] = useState(false);

  const location = {
    lat: formik.values.number.latitude,
    lng: formik.values.number.longitude,
    zoom: 18,
    string: `${formik.values.street.streetName} #${formik.values.number.number}`,
    region: formik.values.region,
    county: formik.values.county,
  };

  return (
    <form autoComplete="off">
      <div className="">
        <h2 className="text-lg font-medium text-gray-900">
          Información de contacto
        </h2>

        <div className="mt-4">
          <label
            htmlFor="email-address"
            className="block text-sm font-medium text-gray-700"
          >
            Email
          </label>
          <div className="mt-1">
            <input
              type="email"
              id="email"
              name="email"
              autoComplete="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            {formik.touched.email && formik.errors.email && (
              <p className="text-xs text-red-500 mt-1">{formik.errors.email}</p>
            )}
          </div>
        </div>

        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
          <div>
            <label
              htmlFor="first-name"
              className="block text-sm font-medium text-gray-700"
            >
              Nombre
            </label>
            <div className="mt-1">
              <input
                type="text"
                id="name"
                name="name"
                autoComplete="given-name"
                value={formik.values.name}
                onChange={formik.handleChange}
                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              {formik.touched.name && formik.errors.name && (
                <p className="text-xs text-red-500 mt-1">
                  {formik.errors.name}
                </p>
              )}
            </div>
          </div>

          <div>
            <label
              htmlFor="last-name"
              className="block text-sm font-medium text-gray-700"
            >
              Apellido
            </label>
            <div className="mt-1">
              <input
                type="text"
                id="lastname"
                name="lastname"
                value={formik.values.lastname}
                onChange={formik.handleChange}
                autoComplete="family-name"
                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              {formik.touched.lastname && formik.errors.lastname && (
                <p className="text-xs text-red-500 mt-1">
                  {formik.errors.lastname}
                </p>
              )}
            </div>
          </div>

          <div className="sm:col-span-2">
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700"
            >
              Teléfono
            </label>
            <div className="mt-1">
              <div className="mt-1 flex rounded-md shadow-sm">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                  +56
                </span>
                <input
                  className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                  placeholder="991234455"
                  type="text"
                  name="phone"
                  id="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  autoComplete="tel"
                />
              </div>

              {formik.touched.phone && formik.errors.phone && (
                <p className="text-xs text-red-500 mt-1">
                  {formik.errors.phone}
                </p>
              )}
            </div>
          </div>
        </div>
        {!formik.values.locationConfirmed && (
          <div className="mt-10 border-t border-gray-200 pt-10">
            <h2 className="text-lg font-medium text-gray-900">
              Dirección de envío
            </h2>
            <div className="sm:col-span-2 mt-4">
              <RegionInput
                name="region"
                value={formik.values.region}
                onChange={formik.setFieldValue}
              />
            </div>
            <div className="sm:col-span-2 mt-4">
              <CountyInput
                value={formik.values.county}
                onChange={formik.setFieldValue}
                region={formik.values.region.id}
              />
            </div>

            <div className="sm:col-span-2 mt-4">
              <StreetInput
                value={formik.values.street}
                setField={formik.setFieldValue}
                county={formik.values.county}
              />
              {formik.touched.street &&
                formik.errors.street &&
                formik.errors.street.streetName && (
                  <p className="text-xs text-red-500 mt-1">
                    {formik.errors.street.streetName}
                  </p>
                )}
            </div>

            <div className="sm:col-span-2 mt-4">
              <NumberInput
                name="number"
                value={formik.values.number}
                setField={formik.setFieldValue}
                street={formik.values.street}
                handleModal={setValidatingAdress}
              />
              {formik.touched.number &&
                formik.errors.number &&
                formik.errors.number.number && (
                  <p className="text-xs text-red-500 mt-1">
                    {formik.errors.number.number}
                  </p>
                )}
            </div>
          </div>
        )}
      </div>

      {formik.values.locationConfirmed && (
        <AddressDisplay
          changeLocation={() =>
            formik.setFieldValue("locationConfirmed", false)
          }
          location={location}
        />
      )}

      {formik.values.locationConfirmed && (
        <>
          <DeliveryMethods
            countyCode={formik.values.county.countyCode}
            regionCode={formik.values.region.id}
            pickMethod={formik.setFieldValue}
          />
          {formik.touched.shipping &&
            formik.errors.shipping &&
            formik.errors.shipping.serviceValue && (
              <p className="text-xs text-red-500 mt-1">
                {formik.errors.shipping.serviceValue}
              </p>
            )}
        </>
      )}

      <ConfirmAddress
        open={validatingAdress}
        setOpen={setValidatingAdress}
        location={location}
        confirmLocation={formik.setFieldValue}
      />
    </form>
  );
};
