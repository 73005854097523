import { Layout, Lottie } from "../../components";
import error from "./error.json";

export const PaymentErrorScreen = () => {
  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-8 flex flex-col items-center">
        <div className="-z-10 lg:h-150 lg:w-150">
          <Lottie src={error} />
        </div>
        <h1 className="text-gray-900 font-semibold text-2xl -mt-16">
          Lo sentimos
        </h1>
        <p className="text-gray-500 text-center mb-48 lg:w-2/5">
          Hubo un problema al realizar el pago. Inténtalo de nuevo en unos
          minutos, si el problema persiste, contactate con tu banco.
        </p>
      </div>
    </Layout>
  );
};
