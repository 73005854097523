import { Icon } from "../../icon";
import { Link } from "react-router-dom";
import lodash from "lodash";
import { removeProductFromCart } from "../../../redux/cart-reducer";
import { useDispatch } from "react-redux";

export const CartList = ({ products }) => {
  const dispatch = useDispatch();
  function currentPricing() {
    const prices = products.map((product) => Number(product.price));
    return lodash.sum(prices).toLocaleString("us");
  }

  function removeProduct(id) {
    dispatch(removeProductFromCart(id));
  }

  return (
    <div className="absolute right-0 shadow-2xl shadow-gray-500/50 border border-0.5 border-gray-100 p-4 rounded-lg bg-white flex flex-col gap-2 mt-2 z-50">
      {products.map((product) => (
        <div
          key={product.id}
          className="flex flex-row gap-2 w-64 items-center mb-2"
        >
          <div
            onClick={() => removeProduct(product.id)}
            className="bg-gray-200 h-6 w-6 rounded-full flex items-center justify-center cursor-pointer"
          >
            <Icon className="text-gray-700 h-4 w-4" icon="xmark" type="far" />
          </div>
          <img src={product.images[0]} className="h-12 w-12 rounded-md" />
          <div>
            <p className="text-md font-semibold">{product.name}</p>
            <p className="text-xs text-gray-400">
              ${Number(product.price).toLocaleString("us")}
            </p>
          </div>
        </div>
      ))}

      <Link
        to="/checkout"
        className="bg-green-500 py-3 px-4 rounded-md flex flex-row justify-between items-center text-white cursor-pointer shadow-md shadow-green-500/50"
      >
        <div>
          <p className="">${currentPricing()}</p>
        </div>

        <div className="flex items-center justify-center gap-2">
          {/* <p className="text-xs">Continuar</p> */}
          <Icon icon="arrow-right" type="far" />
        </div>
      </Link>
    </div>
  );
};
