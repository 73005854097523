import { useState, useEffect } from "react";
import axios from "axios";
import { Icon } from "../../../components";

function capitalize(str) {
  const arr = str.split(" ");
  const upperArr = arr.map(
    (word) => word.charAt(0) + word.slice(1).toLowerCase()
  );
  return upperArr.join(" ");
}

export const StreetInput = ({ setField, error, type, county }) => {
  const [streets, setStreets] = useState(null);
  const [search, setSearch] = useState({ search: "", completed: false });

  const url = `https://testservices.wschilexpress.com/georeference/api/v1.0/streets/search?limit=5`;

  const headers = {
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      "Ocp-Apim-Subscription-Key": "d422d050d8d8422f99cdcdd352362f7d",
    },
  };

  const req = {
    countyName: county.name,
    streetName: search.search,
    pointsOfInterestEnabled: true,
    streetNameEnabled: true,
    roadType: 0,
  };

  useEffect(async () => {
    if (county && !search.completed) {
      const query = await axios.post(url, req, headers);
      const { streets } = query.data;
      setStreets(streets);
    }
  }, [search]);

  return (
    <div>
      <label
        htmlFor="first-name"
        className="block text-sm font-medium text-gray-700"
      >
        Calle / Avenida
      </label>

      <input
        type="text"
        name="street"
        id="street"
        value={search.search}
        onChange={(e) =>
          setSearch({ search: e.target.value, completed: false })
        }
        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        placeholder="Avenida Pajaritos"
        autocomplete="chrome-off"
      />

      {streets && (
        <div className="flex flex-col gap-2 mt-2 bg-gradient-to-r from-indigo-100 via-purple-100 to-pink-100 p-4 rounded-lg">
          <p className="text-xs text-indigo-400 font-medium">
            Selecciona una calle
          </p>
          {streets.map((street) => {
            return (
              <div
                className="p-4 border border-1.5 bg-white shadow-sm rounded-lg flex gap-2 hover:ring-1.5 hover:shadow-indigo-500/40 hover:ring-indigo-500 hover:border-indigo-500"
                onClick={() => {
                  setStreets(null);
                  setSearch({
                    search: capitalize(street.streetName),
                    completed: true,
                  });

                  setField("street", street);
                }}
              >
                <Icon icon="location-arrow" type="far" />
                <p className="text-sm ">{capitalize(street.streetName)}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
