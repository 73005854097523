import { Layout } from "../../components/layout";
import { Collections } from "./collections";
import { Features } from "./features";
import { Hero } from "./hero";
import { Incentives } from "./incentives";
import { Products } from "./products";
import { Specifications } from "./specifications";
import { Cta } from "./cta";
import { Icon } from "../../components/icon";
import { Filters } from "./filters";

export const LandingScreen = () => {
  return (
    <Layout>
      <Hero />

      <Collections />

      <Specifications />
      <Cta />
      <Features />
      <Products />
      <Incentives />
    </Layout>
  );
};
