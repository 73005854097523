import { Icon } from "../../components";

export function Empty() {
  return (
    <button
      type="button"
      className="relative block w-full  border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      <Icon className="w-16 h-16 mb-4" icon="face-frown" type="far" />
      <span className="mt-2 block text-sm font-medium text-gray-900">
        No hay productos con esta combinación
      </span>
    </button>
  );
}
