import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { findSiblings } from "./find-siblings";

export const getProduct = createAsyncThunk("get/product", async (id) => {
  const res = await axios.post(
    `${process.env.REACT_APP_PRODUCTION}getProductOnly`,
    { id }
  );
  return res.data;
});

const initialState = {
  loading: false,
  product: { images: [""] },
  cross: [],
};

export const productDetailReducer = createSlice({
  name: "productDetail",
  initialState,
  reducers: {
    setProduct: (state, action) => {
      const { inventory, product } = action.payload;
      state.product = product;
      state.cross = findSiblings(inventory, product);
    },
  },
  extraReducers: {
    [getProduct.pending]: (state, action) => {
      state.loading = true;
    },
    [getProduct.fulfilled]: (state, action) => {
      state.loading = false;
      state.product = action.payload.product;
      state.cross = action.payload.cross;
    },
    [getProduct.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const { setProduct } = productDetailReducer.actions;

export default productDetailReducer.reducer;
