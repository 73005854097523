import { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import { fad } from "@fortawesome/pro-duotone-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  ProductListScreen,
  OrderDetailScreen,
  ProductDetailScreen,
  LandingScreen,
  CheckoutScreen,
  PaymentErrorScreen,
} from "./screens";

import { useDispatch } from "react-redux";
import { getInventory } from "./redux/inventory-reducer";

import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga4";

import { hotjar } from "react-hotjar";

library.add(fad, far);

const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: true, // enable logs
};

ReactPixel.init("620852635723510", advancedMatching, options);
ReactGA.initialize("G-S92PE81B0W");

hotjar.initialize(2782455, 6);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    ReactGA.send({ hitType: "pageview", page: "lala" });
  }, [pathname]);

  return null;
}

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    ReactPixel.pageView();
    dispatch(getInventory());
  }, []);

  return (
    <BrowserRouter className="App">
      <ScrollToTop />
      <Routes>
        <Route path="/order-detail/:orderID" element={<OrderDetailScreen />} />
        <Route path="/checkout" element={<CheckoutScreen />} />
        <Route path="/product-list" element={<ProductListScreen />} />
        <Route
          path="/product-detail/:productID"
          element={<ProductDetailScreen />}
        />
        <Route path="/payment-error" element={<PaymentErrorScreen />} />
        <Route path="/" element={<LandingScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
