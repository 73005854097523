import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const style = {
  "--fa-primary-color": "#FF0087",
  "--fa-secondary-color": "#FFC511",
  "--fa-primary-opacity": 1,
  "--fa-secondary-opacity": 1,
};

export const Icon = ({ icon = "coffee", size = "lg", className, type }) => {
  return (
    <FontAwesomeIcon
      icon={[type || "fad", icon]}
      size={size}
      className={className}
      swapOpacity
      style={style}
    />
  );
};
