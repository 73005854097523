import { useEffect } from "react";
import { Layout } from "../../components";
import { Form } from "./form";
import { OrderSummary } from "./order-summary";
import { useFormik } from "formik";
import { chxRegions } from "./chilexpress-inputs";
import { useSelector, useDispatch } from "react-redux";
import { pay } from "../../redux/cart-reducer";
import ReactPixel from "react-facebook-pixel";
import * as yup from "yup";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("La dirección no es válida")
    .required("El email es requerido"),
  name: yup.string().required("El nombre es requerido"),
  lastname: yup.string().required("El apellido es requerido"),
  phone: yup
    .string()
    .min(9, "El número debe contener 9 digitos")
    .max(9, "El número debe contener 9 digitos")
    .required("El teléfono es requerido"),
  street: yup.object().shape({
    streetName: yup
      .string()
      .required("La calle es requerida para calcular el valor del envío"),
  }),
  number: yup.object().shape({
    number: yup
      .string()
      .required("La numeración es requerida para calcular el valor de envío"),
  }),
  shipping: yup.object().shape({
    serviceValue: yup.string().required("Escoge una opción de envío"),
  }),
});

export function CheckoutScreen() {
  const dispatch = useDispatch();
  const { products, paymentRoute } = useSelector((state) => state.cart);

  const formik = useFormik({
    initialValues: {
      email: "",
      name: "",
      lastname: "",
      phone: "",
      region: chxRegions[0],
      county: [{ name: "San Bernardo", id: "26", countyCode: "AJAH" }],
      street: { latitude: "", longitude: "" },
      number: {},
      locationConfirmed: false,
      shipping: {},
    },
    validationSchema,
    onSubmit: (values, {resetForm}) => {
      const order = { order: { ...values, products } };
      dispatch(pay(order));
      resetForm()
    },
  });

  useEffect(() => {
    if (paymentRoute !== "") {
      window.location.href = paymentRoute;
    }
  }, [paymentRoute]);

  useEffect(() => {
    ReactPixel.track("InitiateCheckout");
  }, []);

  return (
    <Layout>
      <div className="bg-white">
        <div className="max-w-2xl mx-auto pt-8 pb-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h2 className="sr-only">Finaliza tu compra</h2>

          <form className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
            <Form formik={formik} />
            {console.log("formik", formik)}
            <OrderSummary
              shipping={formik.values.shipping}
              makePayment={formik.handleSubmit}
            />
          </form>
        </div>
      </div>
    </Layout>
  );
}
