import { Link } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/outline";

export const ProductList = ({ products }) => {
  return (
    <div className="mt-6 flow-root px-4 sm:mt-10 sm:px-0">
      <div className="-my-6 divide-y divide-gray-200 sm:-my-10">
        {products.map((product) => (
          <div key={product.id} className="flex py-6 sm:py-10">
            <div className="min-w-0 flex-1 lg:flex lg:flex-col ">
              <div className="lg:flex-1">
                <div className="sm:flex justify-between">
                  <div>
                    <h4 className="font-medium text-gray-900">
                      Aro {product.name} {product.shape} {product.size}
                    </h4>
                    <p className="hidden mt-2 text-sm text-gray-500 sm:block">
                      Aros ultra livianos hechos a mano en resina epóxica
                      coloreados con distintos pigmentos y brillos
                    </p>
                    <div className="mt-4">
                      <p className="text-sm font-medium text-gray-500">
                        {product.weight}
                      </p>
                      <p className="text-sm font-medium text-gray-500">
                        {product.tall} x {product.wide} x {product.deep}
                      </p>
                    </div>
                  </div>
                  <p className="mt-1 font-medium text-gray-900 sm:mt-0 sm:ml-6">
                    ${Number(product.price).toLocaleString("us")}
                  </p>
                </div>
                {/* <div className="mt-2 flex text-sm font-medium sm:mt-4">
                  <a
                    href={product.href}
                    className="text-indigo-600 hover:text-indigo-500"
                  >
                    Ver similares
                  </a>
                  <div className="border-l border-gray-200 ml-4 pl-4 sm:ml-6 sm:pl-6">
                    <Link
                      to={`/product-detail/${product.id}`}
                      className="text-indigo-600 hover:text-indigo-500"
                    >
                      Comprar de nuevo
                    </Link>
                  </div>
                </div> */}
              </div>
              <div className="mt-6 font-medium">
                {product.status === "delivered" ? (
                  <div className="flex space-x-2">
                    <CheckIcon
                      className="flex-none w-6 h-6 text-green-500"
                      aria-hidden="true"
                    />
                    <p>
                      Delivered
                      <span className="hidden sm:inline">
                        {" "}
                        on{" "}
                        <time dateTime={product.datetime}>{product.date}</time>
                      </span>
                    </p>
                  </div>
                ) : product.status === "out-for-delivery" ? (
                  <p>Out for delivery</p>
                ) : product.status === "cancelled" ? (
                  <p className="text-gray-500">Cancelled</p>
                ) : null}
              </div>
            </div>
            <div className="ml-4 flex-shrink-0 sm:m-0 sm:mr-6 sm:order-first">
              <img
                src={product.thumbnail}
                alt={`${product.name} ${product.shape}`}
                className="col-start-2 col-end-3 sm:col-start-1 sm:row-start-1 sm:row-span-2 w-20 h-20 rounded-lg object-center object-cover sm:w-40 sm:h-40 lg:w-52 lg:h-52"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
