import { Layout } from "../../components";
import { Detail } from "./detail";
import { Loader } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getProduct, setProduct } from "../../redux/product-detail-reducer";
import { useParams, useLocation } from "react-router-dom";

export const ProductDetailScreen = () => {
  const { productID } = useParams();
  const { inventory } = useSelector((state) => state.inventory);

  const location = useLocation();

  const routerProduct = location.state;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!routerProduct) {
      dispatch(getProduct(productID));
    } else {
      if (inventory) {
        dispatch(
          setProduct({ inventory, product: routerProduct.routerProduct })
        );
      }
    }
  }, [productID, inventory]);

  const { loading } = useSelector((state) => state.productDetail);

  return (
    <Layout>
      {loading && (
        <div className="mt-40 mb-96 mx-auto">
          <Loader />
        </div>
      )}
      {!loading && <Detail />}
    </Layout>
  );
};
