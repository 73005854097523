import { useDispatch } from "react-redux";
import { setFilter } from "../../redux/inventory-reducer";

import icon1 from "./images/icon1.png";
import icon2 from "./images/icon2.png";
import icon3 from "./images/icon3.png";
import icon4 from "./images/icon4.png";
import { Icon } from "../../components";
import { Link } from "react-router-dom";

const incentives = [
  {
    name: "Aros Grandes",
    description: "Con dimensiones entre los 8cm y los 6cm de alto",
    imageSrc: icon1,
    size: "Grande",
  },
  {
    name: "Aros Medianos",
    description: "Con dimensiones entre los 3,5cm y los 6cm",
    imageSrc: icon2,
    size: "Mediano",
  },
  {
    name: "Aros Pequeños",
    description: "Con dimensiones entre los 1,5cm y los 3,5cm",
    imageSrc: icon3,
    size: "Pequeño",
  },
  {
    name: "Aros Petit",
    description: "Con dimensiones promedio entre 1cm y 1,5cm",
    imageSrc: icon4,
    size: "Petit",
  },
];

export function Collections() {
  const dispatch = useDispatch();

  function setActiveFilter(type, filter) {
    dispatch(setFilter({ type, filter }));
  }

  const Button = ({ label, color, filter }) => {
    return (
      <Link
        to="/product-list"
        onClick={() => setActiveFilter("size", filter)}
        className={`btn-uncolored-lg mt-4 ${color}`}
      >
        {label}
        <Icon icon="arrow-right" type="far" className="ml-2 h-4 w-4" />
      </Link>
    );
  };

  return (
    <div className="bg-gradient-to-r from-red-400 via-pink-400 to-purple-400">
      <Header />
      <div className="max-w-2xl mx-auto py-16 px-4 sm:px-6 sm:pt-16 sm:pb-40 lg:max-w-7xl lg:px-8">
        <div className="grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8">
          <div>
            <img src={incentives[0].imageSrc} alt="" className="h-32 w-auto" />
            <h3 className="mt-6 text-lg font-medium text-red-100">
              {incentives[0].name}
            </h3>
            <p className="mt-2 text-sm text-red-50">
              {incentives[0].description}
            </p>
            <Button
              label="Ir a grandes"
              color="bg-red-300 text-red-700"
              filter={incentives[0].size}
            />
          </div>
          <div>
            <img src={incentives[1].imageSrc} alt="" className="h-32 w-auto" />
            <h3 className="mt-6 text-lg font-medium text-pink-100">
              {incentives[1].name}
            </h3>
            <p className="mt-2 text-sm text-pink-100">
              {incentives[1].description}
            </p>
            <Button
              label="Ir a medianos"
              color="bg-pink-300 text-pink-700"
              filter={incentives[1].size}
            />
          </div>
          <div>
            <img src={incentives[2].imageSrc} alt="" className="h-32 w-auto" />
            <h3 className="mt-6 text-lg font-medium text-pink-100">
              {incentives[2].name}
            </h3>
            <p className="mt-2 text-sm text-pink-100">
              {incentives[2].description}
            </p>
            <Button
              label="Ir a pequeños"
              color="bg-pink-300 text-pink-700"
              filter={incentives[2].size}
            />
          </div>
          <div>
            <img src={incentives[3].imageSrc} alt="" className="h-32 w-auto" />
            <h3 className="mt-6 text-lg font-medium text-pink-100">
              {incentives[3].name}
            </h3>
            <p className="mt-2 text-sm text-pink-100">
              {incentives[3].description}
            </p>
            <Button
              label="Ir a petit"
              color="bg-purple-300 text-purple-700"
              filter={incentives[3].size}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

/* This example requires Tailwind CSS v2.0+ */
export function Header() {
  return (
    <div className="">
      <div className="max-w-7xl mx-auto px-4 pt-16 sm:pt-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="mt-1 text-left text-4xl font-extrabold text-pink-50 sm:text-5xl sm:tracking-tight md:text-center lg:text-6xl">
            Escoge a tu gusto
          </p>
          <p className="max-w-xl mt-5 mx-auto text-xl text-white  text-left md:text-center">
            Para los gustos colores y tamaños. Escoge la medida de aro con las
            dimensiones que más te acomoden
          </p>
        </div>
      </div>
    </div>
  );
}
