function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export function findSiblings(inventory, product) {
  const inventoryWithoutProduct = inventory.filter(
    (item) => item.id !== product.id
  );

  const siblings = inventoryWithoutProduct.filter(
    (item) => item.shape == product.shape
  );

  const chosenSiblings = [];

  if (siblings.length >= 8) {
    siblings.slice(0, 8).forEach((item) => {
      chosenSiblings.push(item);
    });
  }

  if (siblings.length < 8) {
    siblings.slice(0, siblings.length).forEach((item) => {
      chosenSiblings.push(item);
    });
    const diff = 8 - siblings.length;

    inventoryWithoutProduct.slice(0, diff).forEach((item) => {
      chosenSiblings.push(item);
    });
  }

  return chosenSiblings;
}
