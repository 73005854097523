import { DateTime } from "luxon";

function formatDate(date) {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_FULL);
}

export const Tracking = ({
  order,
  product = {
    status: "Preparando para enviar",
    step: 1,
    date: "Marzo 24, 2022",
    datetime: "2021-03-24",
  },
}) => {
  return (
    <div className="py-8">
      <h4 className="text-sm">Fecha de compra</h4>
      <p className="text-sm font-regular text-gray-500 ">
        {formatDate(order.date)}
      </p>
      <div className="mt-6" aria-hidden="true">
        <div className="bg-gray-200 rounded-full overflow-hidden">
          <div
            className="h-2 bg-indigo-600 rounded-full"
            style={{ width: `calc((${product.step} * 2 + 1) / 8 * 100%)` }}
          />
        </div>
        <div className="hidden sm:grid grid-cols-4 text-sm font-medium text-gray-600 mt-6">
          <div className="text-indigo-600">Compra</div>
          <div
            className={`${product.step > 1 ? "text-indigo-600" : ""}
            text-center`}
          >
            Procesando
          </div>
          <div
            className={`${product.step > 1 ? "text-indigo-600" : ""}
              text-center`}
          >
            Enviado
          </div>
          <div
            className={`${product.step > 1 ? "text-indigo-600" : ""}
            text-center`}
          >
            Entregado
          </div>
        </div>
      </div>
    </div>
  );
};
