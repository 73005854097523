import mercadopago from "./images/mercado-pago.png";

export const OrderDetail = ({ order }) => {
  return (
    <div className="mt-12 bg-gray-50 rounded-md p-6">
      <dl className="text-sm text-gray-600 grid grid-cols-4 gap-4 items-center ">
        <div className="flex justify-between md:block md:pt-0">
          <dt className="font-medium text-gray-900">Modo de pago</dt>
          <dd className="md:mt-2">
            <img src={mercadopago} className="h-6 w-auto" />
          </dd>
        </div>
        <div className="flex justify-between font-medium text-gray-900 md:block md:pt-0">
          <dt>Subtotal</dt>
          <dd className="md:mt-2 text-sm text-gray-600 font-normal">
            ${Number(order.totals.subtotal).toLocaleString("us")}
          </dd>
        </div>
        <div className="flex justify-between font-medium text-gray-900 md:block md:pt-0">
          <dt>Costo despacho</dt>
          <dd className="md:mt-2 text-sm text-gray-600 font-normal">
            ${Number(order.totals.shipping).toLocaleString("us")}
          </dd>
        </div>
        <div className="flex justify-between font-medium text-gray-900 md:block md:pt-0">
          <dt>Total</dt>
          <dd className="md:mt-2 text-sm text-gray-600 font-normal">
            ${Number(order.totals.total).toLocaleString("us")}
          </dd>
        </div>
      </dl>
    </div>
  );
};
