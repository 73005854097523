import specOne from "./images/spec1.jpg";
import specTwo from "./images/spec2.jpg";
import specThree from "./images/spec3.jpg";
import specFour from "./images/spec4.jpg";

const features = [
  {
    name: "Durabilidad",
    description:
      "Cada pieza es duradera. Una de las fortalezas de la resina epóxica",
  },
  {
    name: "Color",
    description:
      "Con una amplia cantidad pigmentos creamos piezas basadas en el color",
  },
  {
    name: "Dimensiones",
    description: "Piezas desde 1cm hasta los 8cm de largo",
  },
  {
    name: "Formas",
    description:
      "Constantemente conseguimos nuevos moldes para crear nuevas formas",
  },
  {
    name: "Inspiraciones",
    description:
      "Cada pieza fue creada a partir de los colores de la naturaleza",
  },
  {
    name: "Consideraciones",
    description:
      "Cada pieza es hecho a mano y esto puede conllevar detalles naturales del trabajo",
  },
];

export function Specifications() {
  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-24 px-4 grid items-center grid-cols-1 gap-y-16 gap-x-8 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8 lg:grid-cols-2">
        <div>
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Piezas únicas y exclusivas
          </h2>
          <p className="mt-4 text-gray-500">
            Cada pieza es trabajada a mano con cariño y atención por el detalle
            para que no sea simplemente un accesorio genérico.
          </p>

          <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
            {features.map((feature) => (
              <div key={feature.name} className="border-t border-gray-200 pt-4">
                <dt className="font-medium text-gray-900">{feature.name}</dt>
                <dd className="mt-2 text-sm text-gray-500">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
          <img
            src={specOne}
            alt="Walnut card tray with white powder coated steel divider and 3 punchout holes."
            className="bg-gray-100 rounded-lg"
          />
          <img
            src={specTwo}
            alt="Top down view of walnut card tray with embedded magnets and card groove."
            className="bg-gray-100 rounded-lg"
          />
          <img
            src={specThree}
            alt="Side of walnut card tray with card groove and recessed card area."
            className="bg-gray-100 rounded-lg"
          />
          <img
            src={specFour}
            alt="Walnut card tray filled with cards and card angled in dedicated groove."
            className="bg-gray-100 rounded-lg"
          />
        </div>
      </div>
    </div>
  );
}
