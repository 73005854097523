import { configureStore } from "@reduxjs/toolkit";
import inventoryReducer from "./inventory-reducer";
import productDetailReducer from "./product-detail-reducer";
import cartReducer from "./cart-reducer";

const store = configureStore({
  reducer: {
    inventory: inventoryReducer,
    productDetail: productDetailReducer,
    cart: cartReducer,
  },
});

export default store;
