import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import rayo from "../../products/images/grande-maxi_tormentas-color-fruit.jpg";
import trapecio from "../../products/images/grande-trapecio-brillo_oscuridad.jpg";
import argollas from "../../products/images/mediano-argollas-contraste_brillo.jpg";
import bombitas from "../../products/images/mediano-bombitas-glow-pink.jpg";
import cactus from "../../products/images/mediano-cactus-silvestre.jpg";
import estrellas from "../../products/images/mediano-estrellas-bicolor_brillo.jpg";
import luna from "../../products/images/mediano-medias_luna-gradiente_rosa.jpg";

export function Hero() {
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="pt-16 pb-80 sm:pt-24 sm:pb-40 lg:pt-40 lg:pb-48">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 sm:static">
          <div className="sm:max-w-lg">
            <h1 className="text-4xl font font-extrabold tracking-tight text-gray-900 sm:text-6xl">
              Llena tu rostro de color<span className="text-pink-500">.</span>
            </h1>
            <p className="mt-4 text-xl text-gray-500">
              Acentúa tus rasgos y roba miradas con hermosos aros de resina
              hechos a mano con piezas exclusivas.
            </p>
          </div>
          <div>
            <div className="mt-10">
              {/* Decorative image grid */}
              <div
                aria-hidden="true"
                className="pointer-events-none lg:absolute lg:inset-y-0 lg:max-w-7xl lg:mx-auto lg:w-full"
              >
                <div className="absolute transform sm:left-1/2 sm:top-0 sm:translate-x-8 lg:left-1/2 lg:top-1/2 lg:-translate-y-1/2 lg:translate-x-8">
                  <div className="flex items-center space-x-6 lg:space-x-8">
                    <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                      <div className="w-44 h-64 rounded-lg overflow-hidden sm:opacity-0 lg:opacity-100">
                        <img
                          src={rayo}
                          alt=""
                          className="w-full h-full object-center object-cover"
                        />
                      </div>
                      <div className="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src={trapecio}
                          alt=""
                          className="w-full h-full object-center object-cover"
                        />
                      </div>
                    </div>
                    <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                      <div className="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src={argollas}
                          alt=""
                          className="w-full h-full object-left-bottom object-cover"
                        />
                      </div>
                      <div className="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src={bombitas}
                          alt=""
                          className="w-full h-full object-center object-cover"
                        />
                      </div>
                      <div className="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src={estrellas}
                          alt=""
                          className="w-full h-full object-bottom object-cover"
                        />
                      </div>
                    </div>
                    <div className="flex-shrink-0 grid grid-cols-1 gap-y-6 lg:gap-y-8">
                      <div className="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src={luna}
                          alt=""
                          className="w-full h-full object-center object-cover"
                        />
                      </div>
                      <div className="w-44 h-64 rounded-lg overflow-hidden">
                        <img
                          src={cactus}
                          alt=""
                          className="w-full h-full object-center object-cover"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Link
                to="/product-list"
                className="inline-block text-center bg-gradient-to-r from-purple-400 via-pink-500 to-red-500  rounded-md py-3 px-8 font-medium text-white hover:bg-rose-500"
              >
                Ver colección
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
