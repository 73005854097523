import { useState } from "react";

import { Dropdown } from "../../../components/dropdown";

export const chxRegions = [
  {
    regionId: "RM",
    id: "RM",
    regionName: "METROPOLITANA DE SANTIAGO",
    name: "Metropolitana de Santiago",
    ineRegionCode: 13,
  },
  {
    regionId: "R1",
    id: "R1",
    regionName: "TARAPACA",
    name: "Tarapacá",
    ineRegionCode: 1,
  },
  {
    regionId: "R2",
    id: "R2",
    regionName: "ANTOFAGASTA",
    name: "Antofagasta",
    ineRegionCode: 2,
  },
  {
    regionId: "R3",
    id: "R3",
    regionName: "ATACAMA",
    name: "Atacama",
    ineRegionCode: 3,
  },
  {
    regionId: "R4",
    id: "R4",
    regionName: "COQUIMBO",
    name: "Coquimbo",
    ineRegionCode: 4,
  },
  {
    regionId: "R5",
    id: "R5",
    regionName: "VALPARAISO",
    name: "Valparaíso",
    ineRegionCode: 5,
  },
  {
    regionId: "R6",
    id: "R6",
    regionName: "LIBERTADOR GRAL BERNARDO O HIGGINS",
    name: "Libertador Gral Bernardo O Higgins",
    ineRegionCode: 6,
  },
  {
    regionId: "R7",
    id: "R7",
    regionName: "MAULE",
    name: "Maule",
    ineRegionCode: 7,
  },
  {
    regionId: "R8",
    id: "R8",
    regionName: "BIOBIO",
    name: "Biobío",
    ineRegionCode: 8,
  },
  {
    regionId: "R9",
    id: "R9",
    regionName: "ARAUCANIA",
    name: "Araucanía",
    ineRegionCode: 9,
  },

  {
    regionId: "R10",
    id: "R10",
    regionName: "LOS LAGOS",
    name: "Los Lagos",
    ineRegionCode: 10,
  },
  {
    regionId: "R11",
    id: "R11",
    regionName: "AISEN DEL GRAL C IBANEZ DEL CAMPO",
    name: "Aisén del Gral. C Ibáñez del Campo",
    ineRegionCode: 11,
  },
  {
    regionId: "R12",
    id: "R12",
    regionName: "MAGALLANES Y LA ANTARTICA CHILENA",
    name: "Magallanes y la Antártica Chilena",
    ineRegionCode: 12,
  },
  {
    regionId: "R14",
    id: "R14",
    regionName: "LOS RIOS",
    name: "Los Ríos",
    ineRegionCode: 14,
  },
  {
    regionId: "R15",
    id: "R15",
    regionName: "ARICA Y PARINACOTA",
    name: "Arica y Parinacota",
    ineRegionCode: 15,
  },
  {
    regionId: "R16",
    id: "R16",
    regionName: "NUBLE",
    name: "Ñuble",
    ineRegionCode: 16,
  },
];

export const RegionInput = ({ value, onChange, name, error, type }) => {
  const [regions, setRegions] = useState(chxRegions);

  return (
    <div>
      <label
        htmlFor="first-name"
        className="block text-sm font-medium text-gray-700"
      >
        Región
      </label>

      <Dropdown
        name={name}
        id={name}
        selected={value}
        onChange={onChange}
        values={regions}
      />
    </div>
  );
};
