import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { useState, useEffect } from "react";
import axios from "axios";
import chxLogo from "./images/chx.png";

export const DeliveryMethods = ({ countyCode, regionCode, pickMethod }) => {
  const [deliveryMethods, setDeliveryMethods] = useState([]);
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(
    deliveryMethods[0]
  );

  const req = {
    originCountyCode: "STGO",
    destinationCountyCode: countyCode || "AJAH",
    package: {
      weight: "0.15",
      height: "7",
      width: "7",
      length: "1",
    },
    productType: 3,
    contentType: 1,
    declaredWorth: "6000",
    deliveryTime: 0,
  };

  useEffect(async () => {
    const url =
      "https://testservices.wschilexpress.com/rating/api/v1.0/rates/courier";

    const query = await axios.post(url, req, {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        "Ocp-Apim-Subscription-Key": "09a22156646e4d2aaee9463acb4f806a",
      },
    });

    const { courierServiceOptions } = query.data.data;

    if (regionCode == "RM") {
      setDeliveryMethods([
        ...courierServiceOptions,
        {
          serviceDescription: "24 HORAS",
          serviceValue: "3500",
        },
      ]);
    }
    if (regionCode !== "RM") {
      setDeliveryMethods(courierServiceOptions);
    }
  }, [countyCode, regionCode]);
  return (
    <div className="mt-6">
      <RadioGroup
        value={selectedDeliveryMethod}
        onChange={setSelectedDeliveryMethod}
      >
        <RadioGroup.Label className="text-lg font-medium text-gray-900">
          Opciones de envío
        </RadioGroup.Label>

        <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
          {deliveryMethods.map((deliveryMethod) => (
            <RadioGroup.Option
              key={deliveryMethod.serviceValue}
              value={deliveryMethod}
              className={({ checked, active }) =>
                `
                    ${
                      active
                        ? "ring-2 ring-indigo-500 border-2 border-indigo-500"
                        : ""
                    }
                    relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none`
              }
            >
              {({ checked, active }) => (
                <>
                  <div
                    className="flex w-full"
                    onClick={() => pickMethod("shipping", deliveryMethod)}
                  >
                    <div className="flex flex-col w-full">
                      <RadioGroup.Label
                        as="span"
                        className="block text-sm font-medium text-gray-900"
                      >
                        {deliveryMethod.serviceDescription}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className="mt-1 flex items-center text-xs text-gray-500"
                      >
                        {
                          deliveryServiceCaption[
                            deliveryMethod.serviceDescription
                          ]
                        }
                      </RadioGroup.Description>
                      <div className="flex items-center justify-between mt-6 ">
                        <RadioGroup.Description
                          as="span"
                          className="text-sm font-medium text-gray-900"
                        >
                          $
                          {Number(deliveryMethod.serviceValue).toLocaleString(
                            "us"
                          )}
                        </RadioGroup.Description>
                        {deliveryMethod.finalWeight && (
                          <img
                            className="h-6 w-auto"
                            src={chxLogo}
                            alt="chx logo"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {checked ? (
                    <CheckCircleIcon
                      className="h-5 w-5 text-indigo-600"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div
                    className={`${active ? "border-2" : "border-2"},
                        ${checked ? "border-indigo-500" : "border-transparent"},
                        absolute -inset-px rounded-lg pointer-events-none`}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};

const deliveryServiceCaption = {
  PRIORITARIO:
    "Entrega el día hábil siguiente a la contratación del servicio, hasta las 19:00 horas.",
  EXPRESS:
    "Entrega de 1 a 2 días hábiles siguientes a la contratación del servicio, hasta las 19:00 horas.",
  EXTENDIDO:
    "Entrega entre 2 a 4 días hábiles siguientes a la contratación del servicio, hasta las 19:00 horas.",
  EXTREMOS:
    "Entrega entre 3 a 5 días hábiles siguientes a la contratación del servicio, hasta las 19:00 horas.",
  "24 HORAS": "Entrega dentro de 48 horas hábiles dentro de la RM",
};
