import { useSelector } from "react-redux";
import { Empty } from "./empty";
import { useNavigate, Link } from "react-router-dom";
import { Icon } from "../../components";

export function List() {
  const { inventory, filter } = useSelector((state) => state.inventory);
  const navigate = useNavigate();

  const filteredBySize = inventory.filter((product) => {
    if (filter.size === "") {
      return inventory;
    } else {
      return product.size === filter.size;
    }
  });
  const filteredByShape = filteredBySize.filter((product) => {
    if (filter.shape === "") {
      return filteredBySize;
    } else {
      return product.shape === filter.shape;
    }
  });
  const filteredByPrice = filteredByShape.filter((product) => {
    if (filter.price === "") {
      return filteredByShape;
    } else {
      return product.price === filter.price;
    }
  });
  const filteredByHooktype = filteredByPrice.filter((product) => {
    if (filter.hook === "") {
      return filteredByPrice;
    } else {
      return product.hook === filter.hook;
    }
  });

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-8 overflow-hidden sm:py-0 sm:px-6 lg:px-8">
        {filteredByHooktype.length == 0 && <Empty />}
        {filteredByHooktype.length > 0 && (
          <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 lg:gap-x-8">
            {filteredByHooktype.map((product, i) => (
              <Link
                to={`/product-detail/${product.id}`}
                state={{ routerProduct: product }}
              >
                <div
                  key={`${product.id}-${i}`}
                  target="_blank"
                  className="group text-sm cursor-pointer"
                >
                  <div className="w-full aspect-w-1 aspect-h-1 rounded-lg overflow-hidden bg-gray-100 group-hover:opacity-75">
                    <img
                      src={product.thumbnail}
                      alt={product.imageAlt}
                      className="w-full h-full object-center object-cover"
                    />
                  </div>
                  <h3 className="mt-4 font-medium text-base text-gray-900">
                    {product.name}
                  </h3>
                  <div className="flex flex-row my-2">
                    <Icon
                      className="w-4 h-4 mr-2  text-gray-400"
                      icon="crop-simple"
                      type="far"
                    />
                    <p className="text-sm font-medium text-gray-400 justify-center">
                      {product.tall}cm x {product.wide}cm x {product.deep}cm
                    </p>
                  </div>
                  {product.stock > 0 ? (
                    <div className="bg-emerald-100 px-1.5 py-1 rounded-sm inline-grid">
                      <p className="text-emerald-500 text-xs">
                        {product.stock} unidad disponible
                      </p>
                    </div>
                  ) : (
                    <div className="bg-amber-100 px-1.5 py-1 rounded-sm inline-grid">
                      <p className="text-amber-500 text-xs">A pedido</p>
                    </div>
                  )}

                  <p className="mt-2 font-medium text-gray-900">
                    CLP ${Number(product.price).toLocaleString("us")}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
