import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getInventory = createAsyncThunk("get/inventory", async () => {
  const inventory = await axios.post(
    `${process.env.REACT_APP_PRODUCTION}getProducts`
  );
  return inventory.data;
});

const initialState = {
  inventory: [],
  loading: false,
  filters: [
    {
      name: "Tamaño",
      type: "size",
      options: [],
    },
    {
      name: "Forma",
      type: "shape",
      options: [],
    },
    {
      name: "Precio",
      type: "price",
      options: [],
    },
    {
      name: "Tipo de Anclaje",
      type: "hook",
      options: [],
    },
  ],
  filter: {
    size: "",
    shape: "",
    price: "",
    hook: "",
  },
};

export const inventoryReducer = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const { type, filter } = action.payload;
      if (state.filter[type] === filter) {
        state.filter[type] = "";
      } else {
        state.filter[type] = filter;
      }
    },
  },
  extraReducers: {
    [getInventory.pending]: (state, action) => {
      state.loading = true;
    },
    [getInventory.fulfilled]: (state, action) => {
      const { payload } = action;
      state.inventory = payload;
      state.loading = false;

      const existingSizes = payload.map((prod) => prod.size);
      const existingShapes = payload.map((prod) => prod.shape).sort();
      const existingPrices = payload
        .map((prod) => prod.price)
        .sort((a, b) => a - b);
      const existingHooks = payload.map((prod) => prod.hook);

      state.filters = [
        {
          name: "Tamaño",
          type: "size",
          options: ["Petit", "Pequeño", "Mediano", "Grande"],
        },
        {
          name: "Forma",
          type: "shape",
          options: [...new Set(existingShapes)],
        },
        {
          name: "Precio",
          type: "price",
          options: [...new Set(existingPrices)],
        },
        {
          name: "Tipo de Anclaje",
          type: "hook",
          options: [...new Set(existingHooks)],
        },
      ];
    },
  },
});

export const { setInventory, setFilter } = inventoryReducer.actions;

export default inventoryReducer.reducer;
