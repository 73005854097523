import { StarIcon } from "@heroicons/react/solid";
import { Icon } from "../../components";
import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import { Cross } from "./cross";
import { useDispatch, useSelector } from "react-redux";
import { Images } from "./images";
import { addProductToCart } from "././../../redux/cart-reducer";
import { useLocation } from "react-router-dom";

const StockLabel = ({ stock }) => {
  if (stock < 1) {
    return (
      <div className="bg-amber-500 px-3 py-2 mt-4 rounded-sm inline-grid">
        <p className="text-white  text-sm font-medium">A pedido</p>
      </div>
    );
  }
  return (
    <div className="bg-emerald-500 px-3 py-2 mt-4 rounded-sm inline-grid">
      <p className="text-white text-sm font-medium">
        {stock} unidad disponible
      </p>
    </div>
  );
};

export const Detail = () => {
  const { product, cross } = useSelector((state) => state.productDetail);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (product.name) {
      ReactPixel.track("ViewContent", {
        content_ids: [product.id],
        content_type: "product",
        content_category: product.shape,
        content_name: `${product.name} ${product.shape}`,
      });
    }
  }, [location, product]);

  function addToCart(product) {
    dispatch(addProductToCart(product));
    ReactPixel.track("AddToCart", product);
  }

  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto sm:pt-2 px-4 sm:pb-12 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
          {/* Image gallery */}
          <Images images={product.images} />

          {/* Product info */}
          <div className="mt-4 sm:px-0 lg:mt-0">
            <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">
              {product.name}
            </h1>
            <p className="text-md font-medium tracking-tight text-gray-500">
              {product.shape}
            </p>

            <div className="mt-3">
              <h2 className="sr-only">Product information</h2>
              <p className="text-3xl text-gray-900">
                ${Number(product.price).toLocaleString("us")}
              </p>
            </div>
            <StockLabel stock={product.stock} />
            {/* Reviews */}
            {/* <div className="mt-3">
              <h3 className="sr-only">Reviews</h3>
              <div className="flex items-center">
                <div className="flex items-center">
                  {[0, 1, 2, 3, 4].map((rating) => (
                    <StarIcon
                      key={rating}
                      className={`${
                        product.rating > rating
                          ? "text-pink-500"
                          : "text-gray-300"
                      } h-5 w-5 flex-shrink-0`}
                      aria-hidden="true"
                    />
                  ))}
                </div>
                <p className="sr-only">{product.rating} out of 5 stars</p>
              </div>
            </div> */}
            <div className="mt-6">
              <h3 className="sr-only">Description</h3>

              <p className="text-base text-gray-700 space-y-6 mt-4">
                Aros ultra livianos hechos a mano en resina epóxica coloreados
                con distintos pigmentos y brillos
              </p>
              <p className="text-base text-gray-700 space-y-6 mt-4">
                <Icon icon="ruler" className="w-6 h-6 mr-4" type="far" />
                {product.tall}cm de alto x {product.wide}cm de ancho x{" "}
                {product.deep}
                cm de grosor
              </p>
              <p className="text-base text-gray-700 space-y-6 mt-4">
                <Icon icon="ear" className="w-6 h-6 mr-4" type="far" />
                {product.hook} de acero quirúrgico (hipoalergénicos)
              </p>

              <p className="text-base text-gray-700 space-y-6 mt-4">
                <Icon
                  icon="scale-balanced"
                  className="w-6 h-6 mr-4"
                  type="far"
                />
                {product.weight}gr ({product.weight * 2}gr el par)
              </p>
              <p className="text-base text-gray-700 space-y-6 mt-4">
                <Icon
                  icon="person-carry-box"
                  type="far"
                  className="w-6 h-6 mr-4"
                ></Icon>
                Entregas en 48 horas en Santiago
              </p>
            </div>

            <form className="mt-6">
              <div className="mt-10 flex sm:flex-col1">
                {/* <a
                  href={`https://api.whatsapp.com/send?phone=56993964146&text=Me%20gustar%C3%ADa%20comprar%20el%20producto%20https://kameleon.rocks/product-detail/${product.id}`}
                  type="submit"
                  className="appearance-none w-full"
                > */}
                <div
                  onClick={() => addToCart(product)}
                  className=" shadow-md shadow-pink-500/50 cursor-pointer sm:max-w-xs flex-1 bg-pink-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-pink-500 sm:w-full"
                >
                  Comprar
                </div>
                {/* </a> */}
              </div>
            </form>
            <div>
              {product.stock < 1 ? (
                <div className="bg-amber-100 p-3 mt-6 rounded-md">
                  <p className="text-sm text-amber-500">
                    · Los productos a pedido no están en stock pero pueden ser
                    confeccionados dentro de un periodo de 48 horas a partir del
                    pedido.
                  </p>
                  <p className="text-sm text-amber-500 mt-2">
                    · Al ser un producto hecho a mano, la apariencia con el
                    producto de la imagen podría alcanzar un rango de similitud
                    de entre un 90-95%.
                  </p>
                  <p className="text-sm text-amber-500 mt-2">
                    · El producto es despachado dentro de 48 horas después de
                    ser finalizado.
                  </p>
                </div>
              ) : (
                <p className="text-sm text-gray-400 space-y-6 mt-4">
                  * Al ser piezas únicas hechas a mano, pueden tener pequeños
                  detalles o imperfecciones que los hace especiales
                </p>
              )}
            </div>
            <section aria-labelledby="details-heading" className="mt-12">
              <h2 id="details-heading" className="sr-only">
                Additional details
              </h2>
            </section>
          </div>
        </div>
        <Cross products={cross} />
      </div>
    </div>
  );
};
