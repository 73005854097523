import { useEffect } from "react";
import { useSelector } from "react-redux";
import lodash from "lodash";
import { Loader } from "././../../components/";
import { TrashIcon } from "@heroicons/react/solid";
import { useDispatch } from "react-redux";
import { removeProductFromCart } from "../../redux/cart-reducer";
import { useNavigate } from "react-router-dom";

export const OrderSummary = ({ shipping, makePayment }) => {
  const { products, paymentLoading } = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const totalInProducts = lodash.sum(
    products.map((product) => Number(product.price))
  );
  function totals() {
    if (shipping.serviceValue) {
      const totalInShipping = Number(shipping.serviceValue);
      return totalInProducts + totalInShipping;
    } else {
      return totalInProducts.toLocaleString("us");
    }
  }

  function removeProduct(id) {
    dispatch(removeProductFromCart(id));
  }

  useEffect(() => {
    if (products.length === 0) {
      navigate(-1);
    }
  }, [products]);

  return (
    <div className="mt-10 lg:mt-0">
      <h2 className="text-lg font-medium text-gray-900">Resumen de orden</h2>

      <div className="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
        <h3 className="sr-only">Products en la carta</h3>
        <ul role="list" className="divide-y divide-gray-200">
          {products.map((product) => (
            <li key={`resume-${product.id}`} className="flex py-6 px-4 sm:px-6">
              <div className="flex-shrink-0">
                <img
                  src={product.images[0]}
                  alt={product.name}
                  className="w-20 rounded-md"
                />
              </div>

              <div className="ml-6 flex-1 flex flex-col">
                <div className="flex">
                  <div className="min-w-0 flex-1">
                    <h4 className="text-sm">
                      <p className="font-medium text-gray-700 hover:text-gray-800">
                        {product.name}
                      </p>
                    </h4>
                    <p className="mt-1 text-sm text-gray-500">
                      {product.shape}
                    </p>
                  </div>

                  <div className="ml-4 flex-shrink-0 flow-root">
                    <button
                      onClick={() => removeProduct(product.id)}
                      type="button"
                      className="-m-2.5 bg-white p-2.5 flex items-center justify-center text-gray-400 hover:text-gray-500"
                    >
                      <span className="sr-only">Eliminar</span>
                      <TrashIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>

                <div className="flex-1 pt-2 flex items-end justify-between">
                  <p className="mt-1 text-sm font-medium text-gray-900">
                    ${Number(product.price).toLocaleString("us")}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <dl className="border-t border-gray-200 py-6 px-4 space-y-6 sm:px-6">
          <div className="flex items-center justify-between">
            <dt className="text-sm">Subtotal</dt>
            <dd className="text-sm font-medium text-gray-900">
              ${totalInProducts.toLocaleString("us")}
            </dd>
          </div>
          <div className="flex items-center justify-between">
            <dt className="text-sm">Envío</dt>
            {shipping.serviceValue ? (
              <dd className="text-sm font-medium text-gray-900">
                ${Number(shipping.serviceValue).toLocaleString("us")}
              </dd>
            ) : (
              <dd className="text-xs font-medium text-gray-900">
                Añade tu dirección para calcular
              </dd>
            )}
          </div>

          <div className="flex items-center justify-between border-t border-gray-200 pt-6">
            <dt className="text-base font-medium">Total</dt>
            <dd className="text-base font-medium text-gray-900">
              ${totals().toLocaleString("us")}
            </dd>
          </div>
        </dl>

        <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
          {paymentLoading ? (
            <Loader height={50} width={50} />
          ) : (
            <div
              // type="submit"
              onClick={() => makePayment()}
              className="w-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-md shadow-md py-3 px-4 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 cursor-pointer shadow-purple-500/30 "
            >
              Confirmar orden
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
