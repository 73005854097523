import { useState } from "react";
import { useSelector } from "react-redux";
import { Icon } from "../../icon";
import { CartList } from "./list";
import { useLocation } from "react-router-dom";

export const Cart = () => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();

  const { products } = useSelector((state) => state.cart);
  if (products.length < 1 || pathname === "/checkout") {
    return null;
  }
  return (
    <div className="relative">
      <div
        onClick={() => setOpen(!open)}
        className="cursor-pointer text-white bg-purple-500 flex flex-row gap-2 rounded-lg items-center justify-between px-6 h-12 w-24 shadow-md shadow-purple-500/50"
      >
        <Icon icon="cart-shopping" type="far" />
        <p>{products.length}</p>
      </div>

      {open && <CartList products={products} />}
    </div>
  );
};
