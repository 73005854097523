import { Link } from "react-router-dom";

export function Cta() {
  return (
    <div className="bg-gradient-to-r from-purple-400 via-pink-400 to-red-400">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-white md:text-4xl">
          <span className="block">Quieres ver más?</span>
          <span className="block text-purple-800">
            Revisa nuestra lista de productos.
          </span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
            <Link
              to="/product-list"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-pink-600 bg-pink-200 hover:bg-pink-500 hover:text-pink-50"
            >
              Llévame a los productos
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
