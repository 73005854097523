import GoogleMapReact from "google-map-react";

const AnyReactComponent = () => (
  <div className="h-3 w-3 text-white bg-red-500 rounded-full flex items-center justify-center shadow-md shadow-red-500/50"></div>
);

export const Map = ({ location, size }) => {
  return (
    <div className={`${size || "w-full h-72"} rounded-md overflow-hidden`}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCmWv4AX6ubi8JukThSH3OVxiDv7cNXNis" }}
        center={{ lat: location.lat, lng: location.lng }}
        defaultZoom={location.zoom || 18}
        options={{
          disableDefaultUI: true,
        }}
      >
        <AnyReactComponent
          lat={location.lat}
          lng={location.lng}
          text="My Marker"
        />
      </GoogleMapReact>
    </div>
  );
};
