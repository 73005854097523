import { useState, useEffect } from "react";
import { Loader } from "../../components/loader";

const MainImage = ({ image }) => {
  const [loading, setLoading] = useState(true);

  return (
    <div className="w-full aspect-w-1 aspect-h-1 flex items-center justify-center">
      <div className="pt-24 sm:pt-36 md:pt-40 lg:pt-44">
        <Loader />
      </div>
      <img
        src={image}
        key={image}
        onLoad={() => setLoading(false)}
        className={`${
          loading ? "hidden" : "block"
        }w-full h-full object-center object-cover sm:rounded-lg`}
      />
    </div>
  );
};
const ImagesRow = ({ image: currentImg, images, setImage }) => {
  if (images.length < 2) {
    return <div className="h-4 lg:h-8"></div>;
  }
  return (
    <div className="grid grid-cols-4 gap-4 my-6">
      {images.map((image) => (
        <img
          onClick={() => setImage(image)}
          src={image}
          alt="saxs"
          className={`${
            currentImg === image
              ? "border border-2 ring-2 ring-pink-500 ring-offset-2"
              : ""
          } w-full h-full object-center object-cover rounded-lg cursor-pointer`}
        />
      ))}
    </div>
  );
};

export const Images = ({ images }) => {
  const [currentImage, setCurrentImage] = useState("");
  useEffect(() => {
    setCurrentImage(images[0]);
  }, [images]);

  return (
    <div className="w-full max-w-2xl mx-auto sm:block lg:max-w-none">
      <MainImage image={currentImage} />
      <ImagesRow
        image={currentImage}
        images={images}
        setImage={setCurrentImage}
      />
    </div>
  );
};
