import brand from "./images/brand.png";
import { Cart } from "./cart";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

export function Navbar() {
  const { pathname } = useLocation();
  const { products } = useSelector((state) => state.cart);

  const fixed =
    products.length >= 1 && pathname == "/cart"
      ? "fixed left-0 right-0 max-w-7xl mx-auto px-2 sm:px-4 lg:px-8 h-24 bg-white"
      : "";

  return (
    <div
      className={`z-50 relative bg-white max-w-7xl h-24 flex flex-col justify-center mx-auto px-2 sm:px-4 lg:px-8`}
    >
      <div className={`flex justify-between ${fixed}`}>
        <div className="flex px-2 lg:px-0 justify-between w-full items-center">
          <Link to="/" className="flex-shrink-0 flex items-center">
            <img
              className="block lg:hidden h-12 w-auto"
              src={brand}
              alt="Workflow"
            />
            <img
              className="hidden lg:block h-12 w-auto"
              src={brand}
              alt="Workflow"
            />
          </Link>

          <Cart />
        </div>
      </div>
    </div>
  );
}
