import { useState, useEffect } from "react";

export const StatusButton = ({ status = "approved" }) => {
  const [data, setData] = useState({
    bg: "",
    label: "",
  });

  useEffect(() => {
    switch (status) {
      case "approved":
        return setData({
          bg: "bg-emerald-500 shadow-md shadow-emerald-500/50",
          label: "Aprobado",
        });
      case "pending":
        return setData({
          bg: "bg-yellow-500 shadow-md shadow-yellow-500/50",
          label: "Pendiente",
        });
      case "rejected":
        return setData({
          bg: "bg-red-500 shadow-md shadow-red-500/50",
          label: "Rechazado",
        });
    }
  }, []);

  return (
    <div className="justify-end">
      <p className="text-xs font-semibold mb-2 text-gray-500 text-right">
        Estado del pago
      </p>
      <div
        className={`px-4 text-white font-medium py-1 rounded-md inline-block cursor-none ${data.bg}`}
      >
        <p>{data.label}</p>
      </div>
    </div>
  );
};
