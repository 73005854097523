import { Icon, Map } from "../../components";

export const AddressDisplay = ({ location, changeLocation }) => {
  return (
    <div className="mt-8">
      <h2 className="text-lg font-medium text-gray-900 mb-4">
        Dirección de envío
      </h2>
      <div className="border border-1.5 p-3 flex gap-4 rounded-lg shadow-sm">
        <Map size="h-32 w-32 rounded-lg" location={location} />
        <div className="mt-2">
          <div>
            <p className="font-md font-semibold">{location.string}</p>
            <p className="font-sm ">Comuna de {location.county.name}</p>
            <p className="font-sm text-gray-500">
              Región {location.region.name}
            </p>
          </div>
          <div onClick={changeLocation} className="btn-secondary-xs mt-4">
            <Icon icon="arrow-rotate-right" type="far" className="mr-2" />
            <p>Cambiar dirección</p>
          </div>
        </div>
      </div>
    </div>
  );
};
