import { Layout, Loader } from "../../components";
import { Header } from "./header";
import { List } from "./list";
import { useSelector } from "react-redux";

export const ProductListScreen = () => {
  const { loading } = useSelector((state) => state.inventory);
  return (
    <Layout>
      <Header>
        {loading && <Loader />}
        {!loading && <List />}
      </Header>
    </Layout>
  );
};
