/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/outline";
import { Layout } from "../../components";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { StatusButton } from "./status-button";
import { OrderDetail } from "./order-detail";
import { ProductList } from "./product-list";
import { ShippingAddress } from "./shipping-address";
import { Tracking } from "./tracking";

export function OrderDetailScreen() {
  const [order, setOrder] = useState(null);

  const { orderID } = useParams();

  useEffect(async () => {
    const url = `${process.env.REACT_APP_PRODUCTION}fetchOrder`;
    const query = await axios.post(url, { id: orderID });
    const orderDetail = query.data;
    setOrder(orderDetail);
  }, []);

  if (!order) {
    return null;
  }

  function trimID(id) {
    return id.split("-")[0];
  }

  return (
    <div className="max-w-5xl mx-auto">
      <Layout>
        <div className="bg-white">
          <div className="max-w-5xl mx-auto py-8 sm:px-6">
            <div className="flex justify-between">
              <div className="px-4 sm:px-0 w-full flex justify-between items-center">
                <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                  Detalle del pedido #{trimID(order.id)}
                </h1>
                <StatusButton status={order.status.status} />
              </div>
            </div>

            <Tracking order={order} />
            <ProductList products={order.products} />
            <OrderDetail order={order} />
          </div>
        </div>
      </Layout>
    </div>
  );
}
