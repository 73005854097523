import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Icon } from "../../components";

export function Products() {
  const { inventory } = useSelector((state) => state.inventory);
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    setProductList(productsToShow());
  }, [inventory]);

  //create a function that will return a number between 0 and the length of the inventory array.
  function between(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }

  //create an array with limited length cointaining only random numbers generated above.
  const numbersToShow = [...new Array(8)].map((obj) =>
    between(0, inventory.length)
  );

  //filter the inventory with only those product that has an index identical to one of the random generated numbers
  function productsToShow() {
    const productList = [];
    inventory.forEach((product, i) => {
      if (numbersToShow.includes(i)) {
        return productList.push(product);
      }
    });
    return productList;
  }

  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8 flex flex-col">
        <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center lg:mb-4">
          <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">
            Algunos de nuestros últimos productos
          </h2>
          <Link
            to="/product-list"
            className="inline-flex items-center px-4 py-2 mt-4 rounded-full text-md font-medium bg-indigo-100 text-indigo-800 mr-auto lg:mr-0 lg:mt-0"
          >
            Ver todos
            <Icon icon="arrow-right" className="w-4 h-4 ml-2" type="far" />
          </Link>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {productList.map((product) => (
            <Link
              to={`/product-detail/${product.id}`}
              state={{ routerProduct: product }}
            >
              <div key={product.id} className="group relative">
                <div className="w-full min-h-80 bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                  <img
                    src={product.images[0]}
                    alt={product.imageAlt}
                    className="w-full h-full object-center object-cover lg:w-full lg:h-full"
                  />
                </div>
                <div className="mt-4 flex justify-between">
                  <div>
                    <h3 className="text-md font-semibold text-gray-700">
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.name}
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {product.shape}
                    </p>
                  </div>
                  <p className="text-md font-medium text-gray-900">
                    ${product.price}
                  </p>
                </div>
                <div className="flex flex-row my-2">
                  <Icon
                    className="w-4 h-4 mr-2  text-gray-400"
                    icon="crop-simple"
                    type="far"
                  />
                  <p className="text-sm font-medium text-gray-400 justify-center">
                    {product.tall}cm x {product.wide}cm x {product.deep}cm
                  </p>
                </div>
                <div className="flex flex-row">
                  <Icon
                    className="w-4 h-4 mr-2 text-gray-400"
                    icon="weight-hanging"
                    type="far"
                  />
                  <p className="text-sm font-medium text-gray-400">
                    {product.weight}gr ({product.weight * 2}gr el par)
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <Link
          to="/product-list"
          className="btn-secondary-xl mt-8 w-full lg:max-w-sm flex justify-center mx-auto"
        >
          <p>Ver todos ({inventory.length})</p>
        </Link>
      </div>
    </div>
  );
}
