import { useState, useEffect } from "react";
import axios from "axios";
import { Icon } from "../../../components";

export const NumberInput = ({
  value,
  setField,
  street,
  name,
  error,
  handleModal,
}) => {
  const [numbers, setNumbers] = useState(null);
  const [search, setSearch] = useState({ search: "", completed: false });

  const url = `https://testservices.wschilexpress.com/georeference/api/v1.0/streets/${street.streetId}/numbers?streetNumber=${search.search}`;

  useEffect(async () => {
    if (street && !search.completed) {
      const query = await axios.get(url, {
        headers: {
          "Cache-Control": "no-cache",
          "Ocp-Apim-Subscription-Key": "684362d49e7a4177b8c611b9984367da",
        },
      });

      const { streetNumbers } = query.data;

      setNumbers(streetNumbers);
    }
  }, [search]);

  return (
    <div>
      <label
        htmlFor="street-number"
        className="block text-sm font-medium text-gray-700"
      >
        Numeración
      </label>
      <input
        type="number"
        name="number"
        id="number"
        value={search.search}
        onChange={(e) =>
          setSearch({ search: e.target.value, completed: false })
        }
        className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        placeholder="773"
        autocomplete="chrome-off"
      />
      {numbers && (
        <div className="flex flex-col gap-2 mt-2 bg-gradient-to-r from-indigo-100 via-purple-100 to-pink-100 p-4 rounded-md">
          <p className="text-xs text-indigo-400 font-medium">
            Selecciona una numeración
          </p>
          {numbers.map((number) => {
            return (
              <div
                className="p-4 border border-1.5 bg-white shadow-sm rounded-lg flex gap-2 hover:ring-1.5 hover:shadow-indigo-500/40 hover:ring-indigo-500 hover:border-indigo-500"
                onClick={() => {
                  setSearch({ search: number.number, completed: true });
                  setField("number", number);
                  setNumbers(null);
                  handleModal(true);
                }}
              >
                <Icon icon="location-arrow" type="far" />
                <p>{number.number}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
