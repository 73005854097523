import LottieComponent from "react-lottie";

export const Lottie = ({ height, width, src, loop = true }) => {
  const defaultOptions = {
    loop,
    autoplay: true,
    animationData: src,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return <LottieComponent options={defaultOptions} />;
};
