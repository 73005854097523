import { useState, useEffect } from "react";
import axios from "axios";
import { Dropdown } from "../../../components/dropdown";

function capitalize(str) {
  const arr = str.split(" ");
  const upperArr = arr.map(
    (word) => word.charAt(0) + word.slice(1).toLowerCase()
  );
  return upperArr.join(" ");
}

export const CountyInput = ({
  value,
  onChange,
  name,
  error,
  type,
  region = "RM",
}) => {
  const [counties, setCounties] = useState(null);

  const url = `https://testservices.wschilexpress.com/georeference/api/v1.0/coverage-areas?RegionCode=${region}&type=0`;

  useEffect(async () => {
    const query = await axios.get(url, {
      headers: {
        "Cache-Control": "no-cache",
        "Ocp-Apim-Subscription-Key": "684362d49e7a4177b8c611b9984367da",
      },
    });
    const { coverageAreas } = query.data;

    const existingCounties = coverageAreas.map((county) => {
      return {
        ...county,
        name: capitalize(county.countyName),
        id: county.ineCountyCode,
      };
    });

    setCounties(existingCounties);
  }, [region]);

  return (
    <div>
      <label
        htmlFor="first-name"
        className="block text-sm font-medium text-gray-700"
      >
        Comuna / Pueblo
      </label>
      {counties && (
        <Dropdown
          name="county"
          selected={value.name ? value : counties[0]}
          onChange={onChange}
          values={counties}
        />
      )}
    </div>
  );
};
