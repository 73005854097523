import { Link } from "react-router-dom";

export function Cross({ products }) {
  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-2 lg:max-w-7xl">
        <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">
          Podría también interesarte
        </h2>

        <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
          {products.map((product) => (
            <Link
              to={`/product-detail/${product.id}`}
              state={{ routerProduct: product }}
            >
              <div key={product.id} className="group relative cursor-pointer">
                <div className="w-full h-auto bg-gray-200 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:aspect-none">
                  <img
                    src={product.thumbnail}
                    alt={product.name}
                    className="w-full h-auto object-center object-cover"
                  />
                </div>
                <div className="mt-4 flex justify-between">
                  <div>
                    <h3 className="text-sm text-gray-700">
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.name}
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      {product.shape}
                    </p>
                  </div>
                  <p className="text-sm font-medium text-gray-900">
                    ${product.price}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
